import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class ModelInstanceDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    element_id?: Nullable<number>;
    model_id?: Nullable<number>; 
    position?: Nullable<Array<number>>;
    rotation?: Nullable<Array<number>>;
    uses_heightmap_z?: boolean = false;
    has_3d_tiles?: boolean = false;

    constructor({ id, guid, element_id, model_id, position, rotation, uses_heightmap_z, has_3d_tiles } : { id?: Nullable<number>, guid?: Nullable<string>, element_id?: Nullable<number>, model_id?: Nullable<number>, position?: Nullable<Array<number>>, rotation?: Nullable<Array<number>>, uses_heightmap_z?: boolean, has_3d_tiles?: boolean}) {
        this.id = id;
        this.guid = guid;
        this.element_id = element_id;
        this.model_id = model_id;
        this.position = position;
        this.rotation = rotation;
        this.uses_heightmap_z = uses_heightmap_z ?? false;
        this.has_3d_tiles = has_3d_tiles ?? false;
    }
}

export default class ModelInstance extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private position?: Nullable<Array<number>>;
    private rotation?: Nullable<Array<number>>;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        position: Nullable<Array<number>> = [0, 0, 0],
        rotation: Nullable<Array<number>> = [0, 0, 0]
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.position = position;
        this.rotation = rotation;
    }

    static create(dto: ModelInstanceDTO): ModelInstance
    {
        return new ModelInstance(
            dto.id,
            dto.guid,
            dto.position,
            dto.rotation
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setPosition(position: Array<number>): void
    {
        this.position = position;
    }

    getPosition(): Array<number>
    {
        return this.position;
    }

    setRotation(rotation: Array<number>): void
    {
        this.rotation = rotation;
    }

    getRotation(): Array<number>
    {
        return this.rotation;
    }
}